import React, { useState } from 'react';
import './App.css';
import { t } from './traduccion/t';

function App() {
  const [verTyc, setVerTyc] = useState<boolean>(false);
  const politicsNegrilla = new Set([3, 6, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37, 39, 41, 43, 45, 47, 49, 52, 54, 56, 60, 69, 73, 75, 85, 88, 91]);
  const politicsSubtitulo = new Set([3, 47, 49, 52, 54, 56, 60, 69, 73, 75, 85, 88, 91]);
  const tycNegrilla = new Set([0]);
  const tycSubtitulo = new Set([0]);

  const renderText = (count: number, negrilla: Set<number>, subtitulo: Set<number>, prefix: string) => {
    return Array.from({ length: count }).map((_, idx) => (
      <p
        key={`${prefix}${idx}`}
        style={{
          fontWeight: negrilla.has(idx) ? 'bold' : 'normal',
          color: negrilla.has(idx) ? '#000' : '#999',
          fontSize: subtitulo.has(idx) ? '17px' : '14px',
          textAlign: 'justify',
          margin: '10px 0',
        }}
      >
        {t('es', `${prefix}${idx}`)}
      </p>
    ));
  };

  return verTyc ? (
    <div style={{ padding: '16px', backgroundColor: '#fff', borderRadius: '16px' }}>
      <header style={{ marginBottom: '16px' }}>
        <h1 style={{ fontSize: '20px', fontWeight: '600' }}>{t('es', 'politicsTitle')}</h1>
      </header>
      <section style={{ marginBottom: '32px' }}>
        {renderText(93, politicsNegrilla, politicsSubtitulo, 'politics')}
      </section>
      <header style={{ marginBottom: '16px' }}>
        <h1 style={{ fontSize: '20px', fontWeight: '600' }}>{t('es', 'tycTitle')}</h1>
      </header>
      <section style={{ marginBottom: '32px' }}>
        {renderText(13, tycNegrilla, tycSubtitulo, 'tyc')}
      </section>
    </div>
  ) : (
    <div className="App">
      <header className="App-header">
        <img src='logo.png' alt='Logo'/>
        <p>
          Estamos renovándonos!
        </p>
        <button
        onClick={() => setVerTyc(true)}
        style={{
          padding: '10px 20px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          backgroundColor: 'transparent',
          cursor: 'pointer',
        }}
      >
        {"Términos, Condiciones y Política de Privacidad"}
      </button>
      </header>
    </div>
  );
}

export default App;
