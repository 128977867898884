import {paises, creditosInfo, es} from './es';

export function t(lng: 'es', key: string): string {
  switch (lng) {
    case 'es':
      return es[key] ?? key;
  }
}

export function creditsInfo(
  lng: 'es',
): {name: string; description: string[]; id: string}[] {
  switch (lng) {
    case 'es':
      return creditosInfo;
  }
}

export function countriesInfo(
  lng: 'es',
): {code: string; name: string; phoneCode: string; icon: string}[] {
  switch (lng) {
    case 'es':
      return paises;
  }
}
